import React, { Component } from 'react'
import styles from './SelectBox.module.css'
import { Input } from 'reactstrap'
import { restructureResponse } from '../../helpers/sparql'
import { not, isEmptyObject } from '../../helpers/utils'
import isEqual from 'lodash/isEqual'
import PublishUtils from '../../helpers/PublishUtils'

class SelectBox extends Component {

  static getDescription() {
    return {
      name: 'SelectBox',
      component: SelectBox,
      label: 'Select box widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          defaultQuery:"select distinct ?graph ?label \r\n where \r\n{  \r\n graph ?g  \r\n {    \r\n values(?label ?graph)\r\n {  \r\n     (\"tva\" <http://wistor.nl/ifc/2024/bimlegal/tva>)   \r\n   (\"rhijnkade\" <http://wistor.nl/ifc/2024/bimlegal/rhijnkade>) \r\n   }\r\n  }\r\n}",
          label: 'To fill select box items',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which the URI of the selected item is published.',
        },
        {
          name: 'publishVariable2',
          type: 'text',
          label: 'Variabelename for ?uri2 of the selected item (if available).',
        },
        {
          name: 'publishVariable3',
          type: 'text',
          label: 'Variabelename for ?uri3 of the selected item (if available).',
        },
        {
          name: 'preSelectFirstItem',
          type: 'boolean',
          label: 'pre select first item',
        },
        {
          name: 'label',
          type: 'text',
          label: 'Label',
          topicValueInterpolationEnabled: true
        },
        {
          name: 'extrastyle',
          type: 'text',
          label: 'extra css style ({"border-radius":"20px"})',
        },
       // PublishUtils.getResetValueDef()
      ],
    }
  }

  componentDidMount() 
  {
    if (this.props.data) {
      this.preSelect()
    }
  }

  componentDidUpdate(prevProps) 
  {
    try {
      if (!isEqual(prevProps.data, this.props.data)) {
        this.preSelect()
      }
    } catch (e) {
      console.log(e)
    }
  }

  preSelect() {
    try {
     // console.log("preselect the first item",this.props.preSelectFirstItem);
      if (this.props.preSelectFirstItem!=true) return;
      var value = 'http://www.buildingbits.nl/reset'
      if (this.props.data==null) 
      {
        if (this.props.publishVariable != null) 
        {
          this.props.publish(this.props.publishVariable, value)
        }
        return;
      }
      let item = restructureResponse(this.props.data)[0]
      if (item == null) {
      } else {
        let keyURI = this.props.data.head.vars[0]
        value = item[keyURI]
      }

      if (this.props.publishVariable != null) 
      {
        this.props.publish(this.props.publishVariable, value)
      }
      if (this.props.publishVariable2 != null && item['uri2'] != null) {
       // console.log('initial select publishing uri2 ', item['uri2'])
        this.props.publish(this.props.publishVariable2, item['uri2'])
       
      }
      if (this.props.publishVariable3 != null && item['uri3'] != null) {
        // console.log('initial select publishing uri2 ', item['uri2'])
        
         this.props.publish(this.props.publishVariable3, item['uri3'])
       }
    } catch (e) {
      console.log(e)
    }
  }

  onChange = (event) => {
    let value = event.target.value
    let value2 = null
    let value3 = null

    try {
      value2 = event.target[event.target.selectedIndex].getAttribute('value2')
    } catch (e) {}

    try {
      value3 = event.target[event.target.selectedIndex].getAttribute('value3')
    } catch (e) {}

        this.setState({selected:value});
    if ((this.props.publishVariable != null) && (this.props.publishVariable != "") )
    {
     // PublishUtils.emptyDataParameterChange(this);
      this.props.publish(this.props.publishVariable, value)
    }

    if (this.props.publishVariable2 != null && value2 != null && this.props.publishVariable2 != "") 
    {
      //console.log('select publishing uri2 ',this.props.publishVariable2,value2,event.target[event.target.selectedIndex]);
      this.props.publish(this.props.publishVariable2, value2.toString())
      
    }
    if (this.props.publishVariable3 != null && value3 != null&& this.props.publishVariable3 != "") 
    {
     // console.log('select publishing uri3 ',this.props.publishVariable3,value3.toString());
      this.props.publish(this.props.publishVariable3, value3)
    }
 
  }

  render() 
  {
    let ok=true;
  
    if (!this.props) {ok=false;}
    if (!this.props.data) {ok=false;}
    let items = null
    let keyURI =null;
    let keyLabel =null;

    if (ok)
    {
      try{
      let headVars = this.props.data.head.vars
      if (headVars.length < 2) ok=false;
       keyURI = headVars[0] // graph
       keyLabel = headVars[1] // label
     
      try {
        items = restructureResponse(this.props.data).filter(not(isEmptyObject))
      } catch (er) {
       // console.log(er);
      
        ok=false;
      }
    }
    catch(e){}
  }

    if (items == null) {  items=[]}
    const value = this.props.publishVariable ? this.props.pubsub[this.props.publishVariable] : null
    const stylePanel = styles[this.props.panelstyle]
    const stylePrimaryAction = this.props.colorPrimaryAction ? 'primaryColor' : ''

    var extrastyle={};
    if (this.props.extrastyle!=null)
    {
      try{
          extrastyle=JSON.parse(this.props.extrastyle);
      }
      catch(e){}
    }

    return (
      <div className={styles.main} style={extrastyle}>
        <div className={styles.label}>{this.props.label}</div>
        <Input
          className= {`form-control ${styles.round} ${styles.extra} ${stylePanel} ${stylePrimaryAction}`}
          value={value || ''}
          type="select"
          onChange={this.onChange}
        >
          {/* <option value="">Kies een {this.props.publishVariable}...</option> */}
          {items.map((item) => 
          {
           //var selected=false;
           // if (item[keyURI]==this.state.selected)   selected=true;
            
            return (
           
            <option
              key={item[keyURI]}
              value={item[keyURI]}
              value2={item['uri2']}
              value3={item['uri3']}
            >
              {item[keyLabel]}
            </option>
          ) } 
          )}
        </Input>
      </div>
    )
  }
}

export default SelectBox
