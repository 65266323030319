import { lazy } from 'react'
import { FontList } from "../../../FontList"
import PublishUtils from '../../../../helpers/PublishUtils'

export default class StartRuleButtonDescriptor  {
 
  static getDescription() {
    return {
      name: 'StartRuleButton',
      component: lazy(() =>  import('./StartRuleButton')),
      label: 'StartRuleButton',
      variables: [
           
       
        {
          name: 'disabled',
          type: 'boolean',
          label: 'disabled',
        },
        {
            name: 'title',
            type: 'text',
            label: 'button label',
            topicValueInterpolationEnabled: true
          },
          {
            name: 'beginIcon',
            type: 'text',
            label: 'begin icon',
          },
          {
            name: 'endIcon',
            type: 'text',
            label: 'end icon',
          },
          {
            name: 'tooltip',
            type: 'text',
            label: 'button tooltip',
            topicValueInterpolationEnabled: true
          },
          {
            name: 'startRule',
            type: 'text',
            label: 'start rule with tag. Also EMPTY can be used.',
          },
         
          {
            name: 'parameters',
            type: 'json-text',
            label:
              'extra parameters send in JSON format,Parameters will be resolved before sending. keep it simple. no subchildren',
          },
        
       
        
        PublishUtils.getTimestampParametersDef(),
        {
          name: 'failureMessage',
          type: 'text',
          label: 'failure message',
        },

        {
          name: 'customStyling',
          type: 'boolean',
          label: 'Enable Custom Styling',
        },
        {
          name: 'buttonBorderRadius',
          type: 'text',
          label: 'Custom Styling: button-border-radius (examples: "2lh", "50%", "1.2em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius',
        },
        {
          name: 'textColor',
          type: 'text',
          label: 'Custom Styling: text-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'bgColor',
          type: 'text',
          label: 'Custom Styling: background-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'hasButtonShadow',
          type: 'boolean',
          label: 'Custom Styling: enable button-shadow'
        },
        {
          name: 'buttonFontSize',
          type: 'text',
          label: 'Custom Styling: button-font-size'
        },
        {
          name: 'buttonFontFamily',
          type: 'text',
          label: 'Custom Styling: button-font-family',
          helpComponent: FontList
        },
      ]
    }
  }

  
}
