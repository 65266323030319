
import { lazy } from 'react';
import PublishUtils from '../../../../helpers/PublishUtils';
//import {PublishUtils} from PublishUtils;


export default class BBDFormDescriptor  {
 
  static getDescription() {
    return {
      name: 'BBDForm',
      component: lazy(() =>  import('./BBDForm')),
      label: 'BBDForm',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de form',
          topicValueInterpolationEnabled: true
        },
        {
          name: 'classOrUri',
          type: 'text',
          label: 'uri van de owl/shacl class or individual (not implemented)',
        },
        {
          name: 'addEditToolbar',
          type: 'boolean',
          label: 'add edit toolbar (not implemented yet)',
        },
        {
          name: 'query',
          type: 'yasgui',
          defaultQuery:'PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>\n\r\n\r\n\rPREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n\r\n\r\n\rselect * where\n\r\n\r\n\r{\n\r\n\r  { \n\r\n\r  select ?uri \n\r\n\r    where \n\r\n\r  { \n\r\n\r   bind (uri(concat(\"http://wistor.nl/ifc/appartement/\",replace(str(uuid()),\"urn:uuid:\",\"\") ))\n\r\n\r        as ?uri)    \n\r\n\r  \n\r\n\r    }\n\r\n\r  }\n\r\n\r  \n\r\n\r  bind (<{{graph}}/extra> as ?graph)\n\r\n\r\n\r\n\r     bind (\"Appartement\" as ?title)\n\r\n\r  values (  ?propUri ?label ?value ?type ?hidden)\n\r\n\r  {\n\r\n\r   (  <http://wistor.nl/ifc/verdieping> \"verdieping\" <{{storey}}> \"relation\" true)\n\r\n\r    (  rdfs:label \"naam\" \"appartement naam\" xsd:string false)\n\r\n\r    (  rdfs:comment \"extra\" \"beschrijving\" xsd:string false)\n\r\n\r    ( <http://wistor.nl/ifc/nummer> \"nummer\" 1 xsd:integer false)\n\r\n\r    \n\r\n\r  }\n\r\n\r}',
          label:
            'to fill the form. Use graph,title,uri, propUri, label,value, type ({xsd:types},select,relation,stringlist,textarea), optionLabel optionValue ?required regex, multiple,objectOfCls, disabled,hidden, addmodus. createClass for create button ',
          helpComponent: QueryHelp
        },
       
        {
          name: 'cancelbutton',
          type: 'boolean',
          label: 'show cancel button. (not implemented)',
        },
        
        {
          name: 'readonly',
          type: 'boolean',
          label: 'ready only, no buttons',
        },
        {
          name: 'masseditmode',
          type: 'boolean',
          label: "use multiple uri's but only show prop distinctly. no values for now "
        },
       
        {
          name: 'disabled',
          type: 'boolean',
          label: 'disabled',
        },
        {
          name: 'emptyText',
          type: 'text',
          label: 'text by empty sparql results',
        },
        {
          name: 'hyperlinkPar',
          type: 'text',
          label: 'parameter to update when selecting a hyperlink',
        },

        
        {
          name: 'submit',
          type: 'select',
          label: 'submit handling ',
          options: [
            { label: 'kies', value: 'kies' },
            { label: 'form command', value: 'formCommand' },
            { label: 'internal parameters', value: 'internalparameters' },
            { label: 'javascript', value: 'javascript' },
            
          ],
        },
        {
          name: 'submitbuttonlabel',
          type: 'text',
          label: 'label for the submit button',
          topicValueInterpolationEnabled: true
        },
        {
          name: 'ruleTagAfterSubmit',
          type: 'text',
          label: 'run rule with tag after succesful submit',
        },
        {
          name: 'parameters',
          type: 'json-text',
          label:
            'mandatory extra parameters send in JSON format for rules,Parameters will be resolved before sending. keep it simple. no subchildren',
        },
        
        PublishUtils.getTimestampParametersDef(),
        {
          name: 'successMessage',
          type: 'text',
          label: 'form submission success message',
        },
        {
          name: 'failureMessage',
          type: 'text',
          label: 'failure message',
        },
        {
          name: 'closePopup',
          type: 'boolean',
          label: 'close popup after succesful submit',
        },
      ],
    }
  }

 
}

function QueryHelp() {
  return (
    <div>
      <p>
        <code>?collectionType</code> variables now support the following: <code>rdf:List</code>{' '}
        (full uri: 'http://www.w3.org/1999/02/22-rdf-syntax-ns#List')
      </p>
    </div>
  )
}
